import axios from "axios";
import { Blocked } from "../../../domain/blocked/blocked";
import { Configuration, BlockedApi } from "../generated";
import { BlockedInterface } from "../../../useCase/blocked/adapters/api/interface";

export class BlockedsRepository implements BlockedInterface {
    private service: BlockedApi;

    constructor() {
        this.service = new BlockedApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadBlockedList(offset: number, limit: number): Promise<{count: number, list: Blocked[]} | Error> {
        try {
            let response = await this.service.apiBlockedGet(offset, limit, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let blockeds: Blocked[] = []
                response.data.list!.forEach(blockedData => {
                    let createdAt = new Date(blockedData.created_at!)
                    let blocked = new Blocked(blockedData.id!, blockedData.phone!, createdAt)
                    blockeds.push(blocked)
                });
                return {count: response.data.count!, list: blockeds}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateBlocked(file: File): Promise<string | Error> {
        try {
            let response = await this.service.apiBlockedPost(file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateBlocked(id: number, phone: string): Promise<Blocked | Error> {
        try {
            let response = await this.service.apiBlockedIdPut(id.toString(), {phone: phone}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let blockedData = response.data
                let blocked = new Blocked(blockedData.id!, blockedData.phone!, createdAt)
                return blocked
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateBlockedFile(id: number, phone: string): Promise<Blocked | Error> {
        try {
            let response = await this.service.apiBlockedIdPut(id.toString(), {phone: phone}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let blockedData = response.data
                let blocked = new Blocked(blockedData.id!, blockedData.phone!, createdAt)
                return blocked
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteBlocked(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiBlockedIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
}