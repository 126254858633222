import { Dispatch } from "react"
import { AudioActionTypes } from "../action-types";
import { AudioActions } from "../actions"
import { Audio } from "../../domain/audio/audio";

export const SendAudioListRequest = () => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_REQUEST_SEND,
          });
    }
}

export const AudioListSuccess = (result: {count: number, list: Audio[]}) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_SUCCESS,
            payload: result,
        });
    }
}

export const AudioListError = (error:string) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_ERROR,
            payload: error,
        });
    }
}

export const AudioCreateRequest = () => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_CREATE_REQUEST,
          });
    }
}

export const AudioCreateSuccess = (audio: Audio) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_CREATE_SUCCESS,
            payload: audio,
          });
    }
}

export const AudioCreateError = (message: string) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_CREATE_ERROR,
            payload: message,
          });
    }
}


export const AudioUpdateRequest = () => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_UPDATE_REQUEST,
          });
    }
}

export const AudioUpdateSuccess = (category: Audio) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const AudioUpdateError = (message: string) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const AudioDeleteRequest = () => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_DELETE_REQUEST,
          });
    }
}

export const AudioDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AudioDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AudioActions>) => {
        dispatch({
            type: AudioActionTypes.AUDIO_DELETE_ERROR,
            payload: message,
          });
    }
}