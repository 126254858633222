import { Audience } from "../../../domain/audience/audience";
import { AudienceItem } from "../../../domain/audience/audienceItem";
import { AudienceActionTypes } from "../../action-types";
import { AudienceActions } from "../../actions";
import produce from 'immer';


interface AudienceState {
    loading: boolean | null;
    audiences: Audience[] | null,
    count: number,

    audienceItems: AudienceItem[] | null,
    audienceItemCount: number,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: AudienceState = {
    loading: null,
    audiences: null,
    audienceItems: null,
    audienceItemCount: 0,
    count: 0,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: AudienceState = initialState, action: AudienceActions) => {
    switch (action.type) {
        case AudienceActionTypes.AUDIENCE_REQUEST_SEND:
            state.loading = true;
            return state;
        case AudienceActionTypes.AUDIENCE_SUCCESS:
            state.loading = false;
            state.audiences = action.payload.list;
            state.count = action.payload.count;
            return state;
        case AudienceActionTypes.AUDIENCE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case AudienceActionTypes.AUDIENCE_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case AudienceActionTypes.AUDIENCE_CREATE_SUCCESS:
            state.entityLoading = false
            state.audiences!.push(action.payload)
            state.entityError = ""
            return state;
        case AudienceActionTypes.AUDIENCE_UPDATE_SUCCESS:
            state.entityLoading = false
            state.entityError = ""
            for (let i = 0; i< state.audiences!.length; i ++) {
                if (state.audiences![i].Id() == action.payload.Id()) {
                    state.audiences![i] = action.payload
                    break;
                }
            }
            return state;
        case AudienceActionTypes.AUDIENCE_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case AudienceActionTypes.AUDIENCE_DELETE_SUCCESS:
            state.audiences = state.audiences!.filter((audience) => audience.Id() !== action.payload)
            return state;
        
        case AudienceActionTypes.AUDIENCE_ITEM_SUCCESS:
            state.loading = false;
            state.audienceItems = action.payload.list;
            state.audienceItemCount = action.payload.count;
            return state;
        case AudienceActionTypes.AUDIENCE_ITEM_DELETE_SUCCESS:
            state.audienceItems = state.audienceItems!.filter((item) => item.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
