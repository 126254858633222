import axios from "axios";
import { Campaign } from "../../../domain/campaign/campaign";
import { Configuration, CampaignApi, CampaignStatus, CampaignPriceStruct, CampaignStatisticsOfCampaign, CampaignCampaignSummaryListResponse, CampaignCampaignSummaryResponse, } from "../generated";
import { CampaignInterface } from "../../../useCase/campaign/adapters/api/interface";

export class CampaignsRepository implements CampaignInterface {
    private service: CampaignApi;

    constructor() {
        this.service = new CampaignApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadCampaignList(offset: number, limit: number, status: CampaignStatus): Promise<{count: number, list: Campaign[], progress: {[key: string]: number}, prices: {[key: string]: CampaignPriceStruct}} | Error> {
        try {
            let response = await this.service.apiCampaignGet(offset, limit, status, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let campaigns: Campaign[] = []
                
                response.data.list!.forEach(campaignData => {
                    let createdAt = new Date(campaignData.created_at!)
                    let modifiedAt = new Date(campaignData.modified_at!)
                    let typeR: "audio" | "hangup" = "audio"
                switch (campaignData.type) {
                    case "audio":
                        typeR = "audio"
                        break;
                    case "hangup":
                        typeR = "hangup"
                        break;
                }
                    let campaign = new Campaign(campaignData.id!, campaignData.name!, campaignData.audience_id!, campaignData.audio_id!, campaignData.speed!, campaignData.status!, typeR, createdAt, modifiedAt)
                    campaigns.push(campaign)
                });
                
                return {count: response.data.count!, list: campaigns, progress: response.data.progress!, prices: response.data.prices!}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadCampaignSummaryList(offset: number, limit: number): Promise<{count: number, list: CampaignCampaignSummaryResponse[],} | Error> {
        try {
            let response = await this.service.apiCampaignSummaryGet(offset, limit, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                
                return {count: response.data.count!, list: response.data.list!, }
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateCampaign(name: string, audience_id: number, speed: number, audio_id: number, type : "audio" | "hangup"): Promise<Campaign | Error> {
        try {
            let response = await this.service.apiCampaignPost({name: name, audience_id:audience_id, audio_id: audio_id, speed:speed, type: type}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let campaignData = response.data
                let typeR: "audio" | "hangup" = "audio"
                switch (type) {
                    case "audio":
                        typeR = "audio"
                        break;
                    case "hangup":
                        typeR = "hangup"
                        break;
                }
                let campaign = new Campaign(campaignData.id!, campaignData.name!, campaignData.audience_id!, campaignData.audio_id!, campaignData.speed!, campaignData.status!, typeR, createdAt, modifiedAt)
                return campaign
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateCampaign(id: number, name: string, audience_id: number, status: CampaignStatus, audio_id: number, speed: number, type: "audio" | "hangup"): Promise<Campaign | Error> {
        try {
            let response = await this.service.apiCampaignIdPut(id.toString(), {name: name, audience_id: audience_id, audio_id: audio_id, speed: speed, status: status, type: type}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let campaignData = response.data
                let typeR: "audio" | "hangup" = "audio"
                switch (response.data.type) {
                    case "audio":
                        typeR = "audio"
                        break;
                    case "hangup":
                        typeR = "hangup"
                        break;
                }
                let campaign = new Campaign(campaignData.id!, campaignData.name!, campaignData.audience_id!, campaignData.audio_id!, campaignData.speed!, campaignData.status!, typeR, createdAt, modifiedAt)
                return campaign
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async PauseCampaign(id: number): Promise<Campaign | Error> {
        try {
            let response = await this.service.apiCampaignIdPausePost(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let campaignData = response.data
                let typeR: "audio" | "hangup" = "audio"
                switch (response.data.type) {
                    case "audio":
                        typeR = "audio"
                        break;
                    case "hangup":
                        typeR = "hangup"
                        break;
                }
                let campaign = new Campaign(campaignData.id!, campaignData.name!, campaignData.audience_id!, campaignData.audio_id!, campaignData.speed!, campaignData.status!, typeR, createdAt, modifiedAt)
                return campaign
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async PlayCampaign(id: number): Promise<Campaign | Error> {
        try {
            let response = await this.service.apiCampaignIdPlayPost(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let campaignData = response.data
                let typeR: "audio" | "hangup" = "audio"
                switch (response.data.type) {
                    case "audio":
                        typeR = "audio"
                        break;
                    case "hangup":
                        typeR = "hangup"
                        break;
                }
                let campaign = new Campaign(campaignData.id!, campaignData.name!, campaignData.audience_id!, campaignData.audio_id!, campaignData.speed!, campaignData.status!, typeR, createdAt, modifiedAt)
                return campaign
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }


    async DeleteCampaign(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiCampaignIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CampaignLeadsList(id: number): Promise<File | Error> {
        try {
            let response = await this.service.apiCampaignIdLeadsGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CampaignSuccessList(id: number): Promise<File | Error> {
        try {
            let response = await this.service.apiCampaignIdSuccessGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CampaignUnSuccessList(id: number): Promise<File | Error> {
        try {
            let response = await this.service.apiCampaignIdUnsuccessGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CampaignStatistics(id: number): Promise<CampaignStatisticsOfCampaign | Error> {
        try {
            let response = await this.service.apiCampaignIdStatGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    
}