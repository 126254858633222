import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
 
import { AudioUseCases } from '../useCase/audio/audio'; 
import { CampaignUseCases } from '../useCase/campaign/campaign'; 
import { DidUseCases } from '../useCase/did/did'; 
import { AudienceUseCases } from '../useCase/audience/audiense';
import { BlockedUseCases } from '../useCase/blocked/blocked';

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
     
    audienceUseCase: AudienceUseCases; 
    audioUseCase: AudioUseCases; 
    campaignUseCase: CampaignUseCases; 
    didUseCase: DidUseCases; 
    blockedUseCase: BlockedUseCases; 
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)