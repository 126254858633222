import { Dispatch } from "react"
import { AudienceActionTypes } from "../action-types";
import { AudienceActions } from "../actions"
import { Audience } from "../../domain/audience/audience";
import { AudienceItem } from "../../domain/audience/audienceItem";

export const SendAudienceListRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_REQUEST_SEND,
          });
    }
}

export const AudienceListSuccess = (res: {list: Audience[], count: number}) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_SUCCESS,
            payload: res,
        });
    }
}

export const AudienceListError = (error:string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ERROR,
            payload: error,
        });
    }
}

export const AudienceCreateRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_CREATE_REQUEST,
          });
    }
}

export const AudienceCreateSuccess = (audience: Audience) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_CREATE_SUCCESS,
            payload: audience,
          });
    }
}

export const AudienceCreateError = (message: string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_CREATE_ERROR,
            payload: message,
          });
    }
}


export const AudienceUpdateRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_UPDATE_REQUEST,
          });
    }
}

export const AudienceUpdateSuccess = (category: Audience) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const AudienceUpdateError = (message: string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const AudienceDeleteRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_DELETE_REQUEST,
          });
    }
}

export const AudienceDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AudienceDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_DELETE_ERROR,
            payload: message,
          });
    }
}


export const SendAudienceItemListRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_REQUEST_SEND,
          });
    }
}

export const AudienceItemListSuccess = (res: {list: AudienceItem[], count: number}) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_SUCCESS,
            payload: res,
        });
    }
}

export const AudienceItemListError = (error:string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_ERROR,
            payload: error,
        });
    }
}


export const AudienceItemDeleteRequest = () => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_DELETE_REQUEST,
          });
    }
}

export const AudienceItemDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AudienceItemDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AudienceActions>) => {
        dispatch({
            type: AudienceActionTypes.AUDIENCE_ITEM_DELETE_ERROR,
            payload: message,
          });
    }
}