import { Did } from "../../../domain/did/did";
import { DidActionTypes } from "../../action-types";
import { DidActions } from "../../actions";
import produce from 'immer';


interface DidState {
    loading: boolean | null;
    dids: Did[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: DidState = {
    loading: null,
    dids: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: DidState = initialState, action: DidActions) => {
    switch (action.type) {
        case DidActionTypes.DID_REQUEST_SEND:
            state.loading = true;
            return state;
        case DidActionTypes.DID_SUCCESS:
            state.loading = false;
            state.dids = action.payload;
            return state;
        case DidActionTypes.DID_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case DidActionTypes.DID_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case DidActionTypes.DID_CREATE_SUCCESS:
            state.entityLoading = false
            state.dids!.push(action.payload)
            state.entityError = ""
            return state;
        case DidActionTypes.DID_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case DidActionTypes.DID_UPDATE_SUCCESS:
            state.entityLoading = false
            state.entityError = ""
            for (let i = 0; i< state.dids!.length; i ++) {
                if (state.dids![i].Id() == action.payload.Id()) {
                    state.dids![i] = action.payload
                    break;
                }
            }
            return state;
        case DidActionTypes.DID_DELETE_SUCCESS:
            state.dids = state.dids!.filter((dids) => dids.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
