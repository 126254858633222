import { Did } from "../../domain/did/did";
import { DidsRepository } from "../../repository/api/did/did";
import { actionCreators } from "../../state";

export class DidUseCases {
    private api: DidsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: DidsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadDid = async (offset: number, limit: number, status: "active"|"archived") => {
        this.stateActions.SendDidListRequest()
        let response = await this.api.ReadDids(offset, limit, status)
        if (response instanceof Error) {
            this.stateActions.DidListError(response.message)
            return
        } else {
            this.stateActions.DidListSuccess(response)
        }
    }

    public CreateDid = async (phone: string, callback?: ()=>void) => {
        this.stateActions.DidCreateRequest()
        let response = await this.api.CreateDid(phone)
        if (response instanceof Error) {
            this.stateActions.DidCreateError(response.message)
            return
        } else {
            this.stateActions.DidCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateDid = async (id: number, phone:string, status: "active"|"archived", callback?: ()=>void) => {
        this.stateActions.DidUpdateRequest()
        let response = await this.api.UpdateDid(id, phone, status)
        if (response instanceof Error) {
            this.stateActions.DidUpdateError(response.message)
            return
        } else {
            this.stateActions.DidUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteDid = async (id: number) => {
        this.stateActions.DidDeleteRequest()
        let response = await this.api.DeleteDid(id)
        if (response instanceof Error) {
            this.stateActions.DidDeleteError(response.message)
            return
        } else {
            this.stateActions.DidDeleteSuccess(id)
        }
    }

}