import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { DidList } from "../../pages/did/list";
import { DidAdd } from "../../pages/did/add";
import { AudioList } from "../../pages/audio/list";
import { AudioAdd } from "../../pages/audio/add";
import { AudienceAdd } from "../../pages/audience/add";
import { AudienceList } from "../../pages/audience/list";
import { CampaignAdd } from "../../pages/campaign/add";
import { CampaignList } from "../../pages/campaign/list";
import { BlockedAdd } from "../../pages/blocked/add";
import { BlockedList } from "../../pages/blocked/list";
import { AudienceItem } from "../../../domain/audience/audienceItem";
import { AudienceItemList } from "../../pages/audience/item-list";
import { AudienceItemAdd } from "../../pages/audience/item-add";
import { Statistics } from "../../pages/campaign/statistics";
import { SummaryList } from "../../pages/campaign/summary-list";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
        {/* Заблокированные */}
        <Route path="/blocked/add" element={<BlockedAdd />} />
        <Route path="/blocked" element={<BlockedList />} />

        {/* Компании */}
        <Route path="/campaign/statistic/:id" element={<Statistics />} />
        <Route path="/campaign/add" element={<CampaignAdd />} />
        <Route path="*" element={<CampaignList />} />
        <Route path="/summary" element={<SummaryList />} />

        {/* Аудитории */}
        <Route path="/audience/add" element={<AudienceAdd />} />
        <Route path="/audience" element={<AudienceList />} />
        <Route path="/audience/item/:id" element={<AudienceItemList />} />
        <Route path="/audience/item/:id/add/" element={<AudienceItemAdd />} />

        {/* Аудио файлы */}
        <Route path="/audio/add" element={<AudioAdd />} />
        <Route path="/audio" element={<AudioList />} />
      </> : <>
        <Route path="*" element={<Login />} />
      </>}
    </Routes>
  </Router>
  )
}