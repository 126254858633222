import { Dispatch } from "react"
import { BlockedActionTypes } from "../action-types";
import { BlockedActions } from "../actions"
import { Blocked } from "../../domain/blocked/blocked";

export const SendBlockedListRequest = () => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_REQUEST_SEND,
          });
    }
}

export const BlockedListSuccess = (result: {count: number, list: Blocked[]}) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_SUCCESS,
            payload: result,
        });
    }
}

export const BlockedListError = (error:string) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_ERROR,
            payload: error,
        });
    }
}

export const BlockedCreateRequest = () => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_CREATE_REQUEST,
          });
    }
}

export const BlockedCreateSuccess = (blocked: Blocked) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_CREATE_SUCCESS,
            payload: blocked,
          });
    }
}

export const BlockedCreateError = (message: string) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_CREATE_ERROR,
            payload: message,
          });
    }
}


export const BlockedUpdateRequest = () => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_UPDATE_REQUEST,
          });
    }
}

export const BlockedUpdateSuccess = (category: Blocked) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const BlockedUpdateError = (message: string) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const BlockedDeleteRequest = () => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_DELETE_REQUEST,
          });
    }
}

export const BlockedDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const BlockedDeleteError = (message: string) => {
    return async (dispatch: Dispatch<BlockedActions>) => {
        dispatch({
            type: BlockedActionTypes.BLOCKED_DELETE_ERROR,
            payload: message,
          });
    }
}