import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

export const AudienceAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        name: string,
        file: File | null,
    }>({
        name: "",
        file: null,
    })

    const audience = useTypedSelector(({ audience }) => {
        return audience
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.file) {
            useCases?.audienceUseCase.CreateAudience(form.name!, form.file!, () => { navigate(-1) })
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const fieldsList = () => {
        let fields: any = [
            {
                name: "name",
                title: "Название аудитории",
                placeholder: "Введите название",
                value: form.name,
                type: FiledType.Text,
            },
            {
                name: "file",
                title: "Файл с контактами",
                placeholder: "Приложите файл (в формате csv)",
                value: form.file,
                type: FiledType.File,
                accept: ".csv",
            },
        ]
        return fields
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список аудиторий /</span> Добавить аудиторию</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление аудитории {audience?.entityLoading ?<img style={{maxWidth: "30px", marginLeft: "10px"}} src="/img/loading-gif.gif"/>:<></>}</h5> 
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: audience?.entityLoading!,
                                        error: "",
                                    }}
                                    submit={submit}
                                    fields={fieldsList()}
                                    btnSmall={true}
                                    submitBtnTitle={"Добавить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
