import { Audio } from "../../../domain/audio/audio";
import { AudioActionTypes } from "../../action-types";
import { AudioActions } from "../../actions";
import produce from 'immer';


interface AudioState {
    loading: boolean | null;
    count: number,
    audios: Audio[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: AudioState = {
    loading: null,
    count: 0,
    audios: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: AudioState = initialState, action: AudioActions) => {
    switch (action.type) {
        case AudioActionTypes.AUDIO_REQUEST_SEND:
            state.loading = true;
            return state;
        case AudioActionTypes.AUDIO_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.audios = action.payload.list;
            return state;
        case AudioActionTypes.AUDIO_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case AudioActionTypes.AUDIO_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case AudioActionTypes.AUDIO_CREATE_SUCCESS:
            state.entityLoading = false
            state.audios!.push(action.payload)
            state.entityError = ""
            return state;
        case AudioActionTypes.AUDIO_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case AudioActionTypes.AUDIO_UPDATE_SUCCESS:
            state.entityLoading = false
            state.entityError = ""
            for (let i = 0; i< state.audios!.length; i ++) {
                if (state.audios![i].Id() == action.payload.Id()) {
                    state.audios![i] = action.payload
                    break;
                }
            }
            return state;
        case AudioActionTypes.AUDIO_DELETE_SUCCESS:
            state.audios = state.audios!.filter((audios) => audios.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
