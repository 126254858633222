export class Audience {
     
    private id: number; 
    private name: string; 
    private status: string;
    private itemsCount: number;
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: number, name: string, status: string, itemsCount: number, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.itemsCount = itemsCount;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public Status = (): string => {
        return this.status
    }
    public ItemsCount = (): number => {
        return this.itemsCount
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}