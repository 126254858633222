import axios from "axios";
import { Audio } from "../../../domain/audio/audio";
import { Configuration, AudioApi } from "../generated";
import { AudioInterface } from "../../../useCase/audio/adapters/api/interface";

export class AudiosRepository implements AudioInterface {
    private service: AudioApi;

    constructor() {
        this.service = new AudioApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadAudios(offset: number, limit: number, status: 'active' | 'archived'): Promise<{count: number, list: Audio[]} | Error> {
        try {
            let response = await this.service.apiAudioGet(offset, limit, status, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let audios: Audio[] = []
                response.data.list!.forEach(audioData => {
                    let createdAt = new Date(audioData.created_at!)
                    let modifiedAt = new Date(audioData.modified_at!)
                    let audio = new Audio(audioData.id!, audioData.name!, audioData.file!, audioData.duration!, audioData.status!, createdAt, modifiedAt)
                    audios.push(audio)
                });
                return {count: response.data.count!, list: audios}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateAudio(name: string, file: File): Promise<Audio | Error> {
        try {
            let response = await this.service.apiAudioPost(file, name, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audio(audioData.id!, audioData.name!, audioData.file!, audioData.duration!, audioData.status!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateAudio(id: number, name: string, status: "active" | "archived"): Promise<Audio | Error> {
        try {
            let response = await this.service.apiAudioIdPut(id.toString(), {name: name, status: status}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audio(audioData.id!, audioData.name!, audioData.file!, audioData.duration!, audioData.status!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateAudioFile(id: number, file: File): Promise<Audio | Error> {
        try {
            let response = await this.service.apiAudioFileIdPut(id.toString(),file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audio(audioData.id!, audioData.name!, audioData.file!, audioData.duration!, audioData.status!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteAudio(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiAudioIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    
}