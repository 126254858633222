import { Auth } from "../repository/api/auth/auth"
 
import { AudiencesRepository } from "../repository/api/audience/audience" 
import { AudiosRepository } from "../repository/api/audio/audio" 
import { CampaignsRepository } from "../repository/api/campaign/campaign" 
import { DidsRepository } from "../repository/api/did/did" 
 

import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { DidUseCases } from "../useCase/did/did"
import { AudienceUseCases } from "../useCase/audience/audiense"
import { AudioUseCases } from "../useCase/audio/audio"
import { CampaignUseCases } from "../useCase/campaign/campaign"
import { BlockedUseCases } from "../useCase/blocked/blocked"
import { BlockedsRepository } from "../repository/api/blocked/blocked"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const audienceUseCase = new AudienceUseCases(new AudiencesRepository(), actions) 
    const audioUseCase = new AudioUseCases(new AudiosRepository(), actions) 
    const campaignUseCase = new CampaignUseCases(new CampaignsRepository(), actions) 
    const didUseCase = new DidUseCases(new DidsRepository(), actions) 
    const blockedUseCase = new BlockedUseCases(new BlockedsRepository(), actions) 

    return {authUseCase,  audienceUseCase, audioUseCase, campaignUseCase, didUseCase, blockedUseCase}
}