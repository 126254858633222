import { Blocked } from "../../../domain/blocked/blocked";
import { BlockedActionTypes } from "../../action-types";
import { BlockedActions } from "../../actions";
import produce from 'immer';


interface BlockedState {
    loading: boolean | null;
    blockeds: Blocked[] | null,
    count: number,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: BlockedState = {
    loading: null,
    blockeds: null,
    count: 0,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: BlockedState = initialState, action: BlockedActions) => {
    switch (action.type) {
        case BlockedActionTypes.BLOCKED_REQUEST_SEND:
            state.loading = true;
            return state;
        case BlockedActionTypes.BLOCKED_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.blockeds = action.payload.list;
            return state;
        case BlockedActionTypes.BLOCKED_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case BlockedActionTypes.BLOCKED_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case BlockedActionTypes.BLOCKED_CREATE_SUCCESS:
            state.entityLoading = false
            state.blockeds!.push(action.payload)
            state.entityError = ""
            return state;
        case BlockedActionTypes.BLOCKED_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case BlockedActionTypes.BLOCKED_DELETE_SUCCESS:
            state.blockeds = state.blockeds!.filter((blockeds) => blockeds.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
