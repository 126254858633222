import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

export const CampaignAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState<{
        name: string,
        audience_id: number | null,
        audio_id: number | null,
        speed: number,
        type: "audio" | "hangup"
    }>({
        name: "",
        audience_id: null,
        audio_id: null,
        speed: 10,
        type: "audio"
    })

    const [audienceList, setAudienceList] = useState<{title: string, value: number}[]>([])

    const [audioList, setAudioList] = useState<{title: string, value: number}[]>([])

    const audience = useTypedSelector(({ audience }) => {
        return audience
    })

    const audio = useTypedSelector(({ audio }) => {
        return audio
    })

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.audience_id !== null && (form.audio_id !== null || form.type === "hangup")) {
            let audioId = 0
            if (form.audio_id) {
                audioId = form.audio_id
            }
            setLoading(true)
            await useCases?.campaignUseCase.CreateCampaign(form.name!, form.audience_id, form.speed, audioId, form.type, () => { navigate(-1) })
            setLoading(false)
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const fieldsList = () => {

        
        let fields: any = [
            {
                name: "name",
                title: "Название рассылки",
                placeholder: "Введите название",
                value: form.name,
                type: FiledType.Text,
            },
            {
                name: "audience_id",
                title: "Аудитория",
                placeholder: "Выберите аудиторию рассылки",
                value: form.audience_id,
                type: FiledType.SelectInt,
                options:audienceList,
            },
            {
                name: "speed",
                title: "Задайте скорость рассылки (л.ч)",
                placeholder: "Задайте скорость рассылки (л.ч)",
                value: form.speed,
                type: FiledType.NumberUnlim,
            },
            {
                name: "type",
                title: "Тип рассылки",
                placeholder: "Выберите тип рассылки",
                value: form.type,
                type: FiledType.Select,
                options: [
                    {title: "Аудио", value: "audio"},
                    {title: "Сброс", value: "hangup"},
                ],
            },
        ]

        if (form.type=="audio") {
            fields.push({
                name: "audio_id",
                title: "Аудио",
                placeholder: "Выберите аудио рассылки",
                value: form.audio_id,
                type: FiledType.SelectInt,
                options:audioList,
            })
        }
        return fields
    }

    useEffect(()=>{
        useCases?.audienceUseCase.ReadAudienceList(0, 999, "active")
        useCases?.audioUseCase.ReadAudio(0, 999, "active")
    }, [])

    useEffect(()=>{
        let list: {title: string, value: number}[] = []
        audience?.audiences?.map((item)=>{
            list.push({title: item.Name(), value: item.Id()})
        })

        setAudienceList(list)
    },[audience?.audiences])

    useEffect(()=>{
        let list: {title: string, value: number}[] = []
        audio?.audios?.map((item)=>{
            list.push({title: item.Name(), value: item.Id()})
        })

        setAudioList(list)
    },[audio?.audios])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список рассылок /</span> Добавить рассылку</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление рассылки</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: loading,
                                        error: "",
                                    }}
                                    submit={submit}
                                    fields={fieldsList()}
                                    btnSmall={true}
                                    submitBtnTitle={"Добавить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
