import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Menu } from "../../../components/menu"
import { CampaignStatisticsOfCampaign } from "../../../../repository/api/generated"
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { Cell } from "tabler-icons-react"

export const Statistics = () => {
    const [campaignStatistics, setCampaignStatistics] = useState<CampaignStatisticsOfCampaign | undefined>()
    const [pieChartDate, setPieChartDate] = useState<{ name: string, value: number }[]>([])
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)

    const readStatistics = async (id: number) => {
        let response = await useCases?.campaignUseCase.ReadStatisticsOfCampaign(id)

        if (response instanceof Error) {

        } else {
            setCampaignStatistics(response)
        }
    }

    useEffect(() => {
        if (id) {
            readStatistics(parseInt(id))
        }
    }, [id])

    useEffect(() => {
        if (campaignStatistics) {
            prepareDataForPieChart()
        }
    }, [campaignStatistics])

    const prepareDataForPieChart = () => {
        const data: { name: string, value: number, fill: string }[] = []

        if (campaignStatistics) {


            data.push({
                name: "Лиды",
                value: campaignStatistics.leads_count!,
                fill: '#82ca9d',
            })

            data.push({
                name: "Автоответчики",
                value: campaignStatistics.answering_machine_count!,
                fill: '#8dd1e1',
            })


            data.push({
                name: "Ответили",
                value: campaignStatistics.answered_count!,
                fill: '#83a6ed',
            })

            data.push({
                name: "Перезвонили",
                value: campaignStatistics.recall_count!,
                fill: '#a4de6c',
            })

            data.push({
                name: "Не ответили",
                value: campaignStatistics.not_answered_count!,
                fill: '#339999',
            })


        }
        setPieChartDate(data)
    }

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            percent > 0 ? <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text> : <></>
        );
    };

    const COLORS = ['#82ca9d', '#8dd1e1', '#83a6ed', '#a4de6c', '#339999'];

    const downloadReportLeads = async () => {
        if (id) {
            let response = await useCases?.campaignUseCase.ReadCampaignLeadsList(parseInt(id)!)

            if (!(response instanceof Error)) {
                const blob = new Blob([response!], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', id+'_leads.csv');
                a.click()
                document.body.removeChild(a)
            }
        }


    }

    const downloadReportSuccess = async () => {
        if (id) {
            let response = await useCases?.campaignUseCase.ReadCampaignSuccessList(parseInt(id)!)

            if (!(response instanceof Error)) {
                const blob = new Blob([response!], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', id+'_success.csv');
                a.click()
                document.body.removeChild(a)
            }
        }


    }

    const downloadReportUnSuccess = async () => {
        if (id) {
            let response = await useCases?.campaignUseCase.ReadCampaignUnSuccessList(parseInt(id)!)

            if (!(response instanceof Error)) {
                const blob = new Blob([response!], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', id+'_result.csv');
                a.click()
                document.body.removeChild(a)
            }
        }


    }


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="row" style={{ marginBottom: "20px", marginTop: "40px" }}>
                            <div className="col-lg-8">
                                <h3>Статистика</h3>
                            </div>
                            <div className="col-lg-4" style={{ display: "flex", alignItems: "center" }}>
                                <span>Скачать:</span>
                                <button type="button" className="btn btn-outline-primary waves-effect" style={{ marginLeft: "10px" }} onClick={() => {downloadReportLeads()}}>Лиды</button>
                                <button type="button" className="btn btn-outline-primary waves-effect" style={{ marginLeft: "10px" }} onClick={() => {downloadReportSuccess()}}>Успешные</button>
                                <button type="button" className="btn btn-outline-primary waves-effect" style={{ marginLeft: "10px" }} onClick={() => {downloadReportUnSuccess()}}>Не успешные</button>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-lg-5 mb-4 mb-xl-0" style={{ height: "400px" }}>
                                <ResponsiveContainer height="100%">
                                    <PieChart>
                                        <Pie
                                            data={pieChartDate}
                                            cx="40%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}

                                            innerRadius={130}
                                            outerRadius={185}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {pieChartDate.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={{
                                            top: '50%',
                                            right: 0,
                                            transform: 'translate(0, -50%)',
                                            lineHeight: '24px',
                                        }} />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="col-lg-3 mb-4 mb-xl-0"></div>
                            <div className="col-lg-4 mb-4 mb-xl-0">
                                <div className="alert alert-primary" role="alert">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Всего звонков:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.tasks_count!} / {campaignStatistics?.phone_numbers_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Лиды:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.leads_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Автоответчики:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.answering_machine_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Ответили:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.answered_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Не ответили:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.not_answered_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Перезвонили:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.recall_count!}</span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Средняя цена лида:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.leads_price!.toFixed(2)} <span style={{ fontSize: "13.5px", fontWeight: "500" }}>₽</span></span>
                                    </div>
                                </div>

                                <div className="alert alert-primary" role="alert" style={{ color: "#8c89a6 !important" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#514b4b" }}>Потрачено всего:</span>
                                        <span style={{ color: "#514b4b" }}>{campaignStatistics?.spent_money!.toFixed(2)} <span style={{ fontSize: "13.5px", fontWeight: "500" }}>₽</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}