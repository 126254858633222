import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useNavigate, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import Paginator from "../../../components/paginator/paginator"
import { Filter } from "tabler-icons-react"

export const AudioList = () => {
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(10)

    const [filter, setFilter] = useState<{ status: "active" | "archived", }>({
        status: "active",
    })

    let useCases = useContext(UseCasesContext)

    const audios = useTypedSelector(({ audio }) => {
        return audio
    })

    useEffect(() => {
        useCases?.audioUseCase.ReadAudio(page * countOnPage, countOnPage, filter.status)
    }, [filter, countOnPage, page])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список аудиоматериалов /</span> Аудиоматериалы</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/audio/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить аудио</span></Link>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Filter style={{marginBottom: 15}} />
                            <select style={{ width: 150, marginRight: 10, marginLeft: 10 }} value={filter.status} onChange={(e) => setFilter({ ...filter, status: e.target.value === "active" ? "active" : "archived" })} name="source" className="form-select mb-3">
                                <option selected value="active">Активный</option>
                                <option selected value="archived">Архивный</option>
                            </select>
                            <Paginator allCount={audios?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>
                        <div className="card">
                            <div className="table-responsive text-nowrap_">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Дата</th>
                                            <th>Название</th>
                                            <th>Аудио</th>
                                            <th>Статус</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            audios?.audios && audios.audios.map((audio, index) => {
                                                if (audio.Status() === filter.status) {
                                                    return <TableRow audio={audio} last={audios?.audios!.length - 1 === index ? true : false} />
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "10px", justifyContent: "end" }}>
                            <Paginator allCount={audios?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}