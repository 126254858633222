import { CampaignsRepository } from "../../repository/api/campaign/campaign";
import { CampaignStatus } from "../../repository/api/generated";
import { actionCreators } from "../../state";

export class CampaignUseCases {
    private api: CampaignsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: CampaignsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadCampaignList = async (offset: number, limit: number, status: string) => {
        console.log(convertToCampaignStatus(status))
        this.stateActions.SendCampaignListRequest()
        let response = await this.api.ReadCampaignList(offset, limit, convertToCampaignStatus(status))
        if (response instanceof Error) {
            this.stateActions.CampaignListError(response.message)
            return
        } else {
            this.stateActions.CampaignListSuccess(response)
        }
    }

    public ReadCampaignSummaryList = async (offset: number, limit: number) => {
        let response = await this.api.ReadCampaignSummaryList(offset, limit)
        return response
    }

    public CreateCampaign = async (name: string, audience_id: number, speed: number, audio_id: number, type: "audio" | "hangup", callback?: ()=>void) => {
        this.stateActions.CampaignCreateRequest()
        let response = await this.api.CreateCampaign(name, audience_id, speed, audio_id, type)
        if (response instanceof Error) {
            this.stateActions.CampaignCreateError(response.message)
            return
        } else {
            this.stateActions.CampaignCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateCampaign = async (id: number, name: string, audience_id:number, status: string, audio_id: number, speed: number, type: "audio" | "hangup", callback?: ()=>void) => {
        this.stateActions.CampaignUpdateRequest()
        let response = await this.api.UpdateCampaign(id, name, audience_id, convertToCampaignStatus(status),  audio_id, speed, type)
        if (response instanceof Error) {
            this.stateActions.CampaignUpdateError(response.message)
            return
        } else {
            this.stateActions.CampaignUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public PauseCampaign = async (id: number, callback?: ()=>void) => {
        this.stateActions.CampaignUpdateRequest()
        let response = await this.api.PauseCampaign(id)
        if (response instanceof Error) {
            this.stateActions.CampaignUpdateError(response.message)
            return
        } else {
            this.stateActions.CampaignUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public PlayCampaign = async (id: number, callback?: ()=>void) => {
        this.stateActions.CampaignUpdateRequest()
        let response = await this.api.PlayCampaign(id)
        if (response instanceof Error) {
            this.stateActions.CampaignUpdateError(response.message)
            return
        } else {
            this.stateActions.CampaignUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteCampaign = async (id: number) => {
        this.stateActions.CampaignDeleteRequest()
        let response = await this.api.DeleteCampaign(id)
        if (response instanceof Error) {
            this.stateActions.CampaignDeleteError(response.message)
            return
        } else {
            this.stateActions.CampaignDeleteSuccess(id)
        }
    }

    public ReadCampaignLeadsList = async (id: number) => {
        let response = await this.api.CampaignLeadsList(id)
        return response
    }

    public ReadCampaignSuccessList = async (id: number) => {
        let response = await this.api.CampaignSuccessList(id)
        return response
    }

    public ReadCampaignUnSuccessList = async (id: number) => {
        let response = await this.api.CampaignUnSuccessList(id)
        return response
    }

    public ReadStatisticsOfCampaign = async (id: number) => {
        let response = await this.api.CampaignStatistics(id)
        return response
    }



}

const convertToCampaignStatus: (input: string) => CampaignStatus = (input: string) =>{
    switch (input) {
        case "processing":
            return CampaignStatus.Processing;
        case "canceled":
            return CampaignStatus.Canceled;
        case "finished":
            return CampaignStatus.Finished;
        case "archived":
            return CampaignStatus.Archived;
        case "paused":
            return CampaignStatus.Paused
        default:
            return CampaignStatus.EmptyStatus;
    }
}