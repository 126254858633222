import { useContext, useState } from "react";
import { Analyze, Archive, DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { AudienceItem } from "../../../../domain/audience/audienceItem";

export const TableRow = (props: { audienceItem: AudienceItem, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }



    return (<tr>
        <td>{props.audienceItem.Id()}</td>
        <td>{props.audienceItem.Phone()}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={() => { setShowMenu(!showMenu) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <Trash size={20} onClick={(e)=>{e.preventDefault(); useCases?.audienceUseCase.DeleteAudienceItem(props.audienceItem.Id()); setShowMenu(false)}} />
                </button>
            </div>
        </td>
    </tr>)
}
