export enum CampaignActionTypes {
    CAMPAIGN_REQUEST_SEND = 'campaign_request_send',
    CAMPAIGN_ERROR = 'campaign_error',
    CAMPAIGN_SUCCESS = 'campaign_success',
    
    CAMPAIGN_CREATE_REQUEST = 'campaign_create_request',
    CAMPAIGN_CREATE_SUCCESS = 'campaign_create_success',
    CAMPAIGN_CREATE_ERROR = 'campaign_create_error',

    CAMPAIGN_DELETE_REQUEST = 'campaign_delete_request',
    CAMPAIGN_DELETE_SUCCESS = 'campaign_delete_success',
    CAMPAIGN_DELETE_ERROR = 'campaign_delete_error',

    CAMPAIGN_UPDATE_REQUEST = 'campaign_update_request',
    CAMPAIGN_UPDATE_SUCCESS = 'campaign_update_success',
    CAMPAIGN_UPDATE_ERROR = 'campaign_update_error',
}