import axios from "axios";
import { Audience } from "../../../domain/audience/audience";
import { Configuration, AudienceApi } from "../generated";
import { AudienceInterface } from "../../../useCase/audience/adapters/api/interface";
import { AudienceItem } from "../../../domain/audience/audienceItem";

export class AudiencesRepository implements AudienceInterface {
    private service: AudienceApi;

    constructor() {
        this.service = new AudienceApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }


    async ReadAudienceList(offset: number, limit: number, status: 'active' | 'archived'): Promise<{count: number, list: Audience[]} | Error> {
        try {
            let response = await this.service.apiAudienceGet(offset, limit, status, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let audios: Audience[] = []
                response.data.list!.forEach(audioData => {
                    let createdAt = new Date(audioData.created_at!)
                    let modifiedAt = new Date(audioData.modified_at!)
                    let audio = new Audience(audioData.id!, audioData.name!,  audioData.status!, audioData.count!, createdAt, modifiedAt)
                    audios.push(audio)
                });
                return {count: response.data.count!, list: audios}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateAudience(name: string, file: File): Promise<Audience | Error> {
        try {
            let response = await this.service.apiAudiencePost(file, name, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audience(audioData.id!, audioData.name!, audioData.status!, audioData.count!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateAudience(id: number, name: string, status: "active" | "archived"): Promise<Audience | Error> {
        try {
            let response = await this.service.apiAudienceIdPut(id.toString(), {name: name, status: status}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audience(audioData.id!, audioData.name!, audioData.status!, audioData.count!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateItemsInAudience(audienceId: number, file: File): Promise<string | Error> {
        try {
            let response = await this.service.apiAudienceIdItemPut(audienceId.toString(), file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateAudienceFile(id: number, file: File): Promise<Audience | Error> {
        try {
            let response = await this.service.apiAudienceIdPut(id.toString(),file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let audioData = response.data
                let audio = new Audience(audioData.id!, audioData.name!, audioData.status!, audioData.count!, createdAt, modifiedAt)
                return audio
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteAudience(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiAudienceIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    

    async ReadAudienceListItems(audienceId: number, offset: number, limit: number): Promise<{count: number, list: AudienceItem[]} | Error> {
        try {
            let response = await this.service.apiAudienceItemIdGet(audienceId.toString(), offset, limit, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let audios: AudienceItem[] = []
                response.data.list!.forEach(audioData => {
                    let audio = new AudienceItem(audioData.id!, audioData.audience_id!, audioData.phone!)
                    audios.push(audio)
                });
                return {count: response.data.count!, list: audios}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteAudienceItem(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiAudienceItemIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    
}