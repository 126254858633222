export enum BlockedActionTypes {
    BLOCKED_REQUEST_SEND = 'blocked_request_send',
    BLOCKED_ERROR = 'blocked_error',
    BLOCKED_SUCCESS = 'blocked_success',
    
    BLOCKED_CREATE_REQUEST = 'blocked_create_request',
    BLOCKED_CREATE_SUCCESS = 'blocked_create_success',
    BLOCKED_CREATE_ERROR = 'blocked_create_error',

    BLOCKED_DELETE_REQUEST = 'blocked_delete_request',
    BLOCKED_DELETE_SUCCESS = 'blocked_delete_success',
    BLOCKED_DELETE_ERROR = 'blocked_delete_error',

    BLOCKED_UPDATE_REQUEST = 'blocked_update_request',
    BLOCKED_UPDATE_SUCCESS = 'blocked_update_success',
    BLOCKED_UPDATE_ERROR = 'blocked_update_error',
}