import { Campaign } from "../../../domain/campaign/campaign";
import { CampaignPriceStruct } from "../../../repository/api/generated";
import { CampaignActionTypes } from "../../action-types";
import { CampaignActions } from "../../actions";
import produce from 'immer';


interface CampaignState {
    loading: boolean | null;
    campaigns: Campaign[] | null,
    progress: {[key: string]: number} | null,
    prices: {[key: string]: CampaignPriceStruct} | null,
    count: number,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: CampaignState = {
    loading: null,
    campaigns: null,
    progress: null,
    prices: null,
    count: 0,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: CampaignState = initialState, action: CampaignActions) => {
    switch (action.type) {
        case CampaignActionTypes.CAMPAIGN_REQUEST_SEND:
            state.loading = true;
            return state;
        case CampaignActionTypes.CAMPAIGN_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.campaigns = action.payload.list;
            state.progress = action.payload.progress;
            state.prices = action.payload.prices;
            return state;
        case CampaignActionTypes.CAMPAIGN_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case CampaignActionTypes.CAMPAIGN_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case CampaignActionTypes.CAMPAIGN_CREATE_SUCCESS:
            state.entityLoading = false
            state.campaigns!.push(action.payload)
            state.entityError = ""
            return state;
        case CampaignActionTypes.CAMPAIGN_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case CampaignActionTypes.CAMPAIGN_UPDATE_SUCCESS:
            state.entityLoading = false
            state.entityError = ""
            for (let i = 0; i< state.campaigns!.length; i ++) {
                if (state.campaigns![i].Id() == action.payload.Id()) {
                    state.campaigns![i] = action.payload
                    break;
                }
            }
            return state;
        case CampaignActionTypes.CAMPAIGN_DELETE_SUCCESS:
            state.campaigns = state.campaigns!.filter((campaigns) => campaigns.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
