export class AudienceItem {
     
    private id: number; 
    private audienceId: number; 
    private phone: string; 

    constructor( id: number, audienceId: number, phone: string) {
        this.id = id;
        this.audienceId = audienceId;
        this.phone = phone;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public AudienceId = (): number => {
        return this.audienceId
    }
    public Phone = (): string => {
        return this.phone
    }
}