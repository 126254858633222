export enum DidActionTypes {
    DID_REQUEST_SEND = 'did_request_send',
    DID_ERROR = 'did_error',
    DID_SUCCESS = 'did_success',
    
    DID_CREATE_REQUEST = 'did_create_request',
    DID_CREATE_SUCCESS = 'did_create_success',
    DID_CREATE_ERROR = 'did_create_error',

    DID_DELETE_REQUEST = 'did_delete_request',
    DID_DELETE_SUCCESS = 'did_delete_success',
    DID_DELETE_ERROR = 'did_delete_error',

    DID_UPDATE_REQUEST = 'did_update_request',
    DID_UPDATE_SUCCESS = 'did_update_success',
    DID_UPDATE_ERROR = 'did_update_error',
}