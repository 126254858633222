import { useContext, useState } from "react";
import { Analyze, Archive, DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { Audio } from "../../../../domain/audio/audio";

export const TableRow = (props: { audio: Audio, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }



    return (<tr>
        <td>{props.audio.Id()}</td>
        <td>{formatDate(props.audio.CreatedAt())}</td>
        <td>{props.audio.Name()}</td>
        <td><audio style={{margin: 0, padding: 0, width: "100%"}} controls src={process.env.REACT_APP_BACKEND_URL + "/api/static/"+props.audio.File()}></audio></td>
        <td>{props.audio.Status() === "active" ? "Активный" : "Архив"}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={() => { setShowMenu(!showMenu) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu ? "dropdown-menu show" : "dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" } : { position: "absolute", marginLeft: "-40%", } : { display: "none" }}>
                    <a className="dropdown-item d-flex" href="#" onClick={(e)=>{e.preventDefault(); useCases?.audioUseCase.UpdateAudio(props.audio.Id(), props.audio.Name(), props.audio.Status() === "archived"?"active":"archived"); setShowMenu(false)}}>{props.audio.Status() === "archived"?<Analyze className="me-1" size={20} />:<Archive className="me-1" size={20} />}{props.audio.Status() === "archived" ? "В актив":"В архив"}</a>
                </div>
            </div>
        </td>
    </tr>)
}
