export class Blocked {
     
    private id: number; 
    private phone: string; 
    private createdAt: Date;

    constructor( id: number, phone: string, createdAt: Date,) {
        this.id = id;
        this.phone = phone;
        this.createdAt = createdAt;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public Phone = (): string => {
        return this.phone
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
}