export enum AudioActionTypes {
    AUDIO_REQUEST_SEND = 'audio_request_send',
    AUDIO_ERROR = 'audio_error',
    AUDIO_SUCCESS = 'audio_success',
    
    AUDIO_CREATE_REQUEST = 'audio_create_request',
    AUDIO_CREATE_SUCCESS = 'audio_create_success',
    AUDIO_CREATE_ERROR = 'audio_create_error',

    AUDIO_DELETE_REQUEST = 'audio_delete_request',
    AUDIO_DELETE_SUCCESS = 'audio_delete_success',
    AUDIO_DELETE_ERROR = 'audio_delete_error',

    AUDIO_UPDATE_REQUEST = 'audio_update_request',
    AUDIO_UPDATE_SUCCESS = 'audio_update_success',
    AUDIO_UPDATE_ERROR = 'audio_update_error',
}