import { useContext, useEffect, useState } from "react";
import { UseCasesContext } from "../../../../context/useCases";
import { CampaignCampaignSummaryResponse, CampaignPriceStruct, CampaignStatus } from "../../../../repository/api/generated";


export const TableRow = (props: { summary: CampaignCampaignSummaryResponse, last: boolean, }) => {
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    useEffect(() => {
        useCases?.audioUseCase.ReadAudio(0, 9999, "active")
    }, [])


    return (<tr>
        <td>{formatDate(new Date(props.summary.created_at!))}</td>
        <td>{props.summary.name!}</td>
        <td>{props.summary.leads_count}</td>
        <td>{props.summary.calls_count}</td>
        <td>{props.summary.money!.toFixed(2) + " руб."} / {props.summary.leads_price!.toFixed(2) + " руб."}</td>
        <td>{props.summary.campaign_type === "audio" ? "Аудио" : "Сброс"}</td>
    </tr>)
}
