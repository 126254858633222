import { Biohazard, ChartArcs, Friends, Logout, Playlist, Robot, Switch3 } from 'tabler-icons-react';

export const Items = [

    {
        title: "Рассылки",
        icon: <Robot size={24} className="menu-icon"  />,
        href: "/campaign",
    },

    {
        title: "Статистика",
        icon: <ChartArcs size={24} className="menu-icon"  />,
        href: "/summary",
    },

    {
        title: "Аудитории",
        icon: <Friends size={24} className="menu-icon"  />,
        href: "/audience",
    },

    {
        title: "Аудиоматериалы",
        icon: <Playlist size={24} className="menu-icon"  />,
        href: "/audio",
    },

    /*{
        title: "Подменные номера",
        icon: <Switch3 size={24} className="menu-icon"  />,
        href: "/did",
    },*/

    {
        title: "Заблокированные",
        icon: <Biohazard size={24} className="menu-icon"  />,
        href: "/blocked",
    },
    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]