import { useContext, useEffect, useState } from "react";
import { Archive, Download, Square, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { Campaign } from "../../../../domain/campaign/campaign";
import { Pause, PieChart, Play } from "react-feather";
import { CampaignPriceStruct, CampaignStatus } from "../../../../repository/api/generated";

import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import ProgressBar from "@ramonak/react-progress-bar";

export const TableRow = (props: { campaign: Campaign, last: boolean, progress: number, price: CampaignPriceStruct, }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    const audio = useTypedSelector(({ audio }) => {
        return audio
    })

    const getStatusName: () => string = () => {
        switch (props.campaign.Status()) {
            case "archived":
                return "Архив"
            case "processing":
                return "Обработка"
            case "paused":
                return "Пауза"
            case "canceled":
                return "Отменен"
            case "finished":
                return "Завершен"
            default:
                return props.campaign.Status()
        }
    }

    const pickAudioFile = () => {
        if (!audio?.audios) {
            return ""
        }

        for (let i = 0; i < audio?.audios.length; i++) {
            if (audio?.audios[i].Id() == props.campaign.AudioId()) {
                return audio?.audios[i].File()
            }
        }

    }

    useEffect(() => {
        useCases?.audioUseCase.ReadAudio(0, 9999, "active")
    }, [])


    return (<tr>
        <td>{props.campaign.Id()}</td>
        <td>{formatDate(props.campaign.CreatedAt())}</td>
        <td>{props.campaign.Name()}</td>
        <td><ProgressBar completed={Math.round(props.progress*100)} bgColor={"#7367f0"}/></td>
        <td>{getStatusName()}</td>
        <td>{props.campaign.Speed() + " л/ч"}</td>
        {props.price?<td>{props.price.total_price!.toFixed(2) + " руб."} / {props.price.one_lead_price!.toFixed(2) + " руб."} / {props.price.success_leads_count}</td>:<td></td>}
        <td>{props.campaign.Type() === "audio" ? "Аудио" : "Сброс"}</td>
        <td>{props.campaign.Type() === "audio" ? <audio style={{ margin: 0, padding: 0, width: "100%" }} controls src={"/api/static/" + pickAudioFile()}></audio> : "Нет"}</td>
        <td>
            {props.campaign.Status() === "paused" || props.campaign.Status() === "canceled" || props.campaign.Status() === "archived" ? <span style={{ margin: 0, padding: 0, cursor: "pointer" }} onClick={() => { useCases?.campaignUseCase.PlayCampaign(props.campaign.Id()) }} data-title="Запустить"><Play size={25} /></span> : <></>}
            {props.campaign.Status() === "processing" ? <span style={{ margin: 0, padding: 0, cursor: "pointer" }} onClick={() => { useCases?.campaignUseCase.PauseCampaign(props.campaign.Id()) }} data-title="Пауза"><Pause size={25} /></span> : <></>}
            {props.campaign.Status() === "processing" || props.campaign.Status() === "paused" ? <span style={{ margin: 0, padding: 0, cursor: "pointer" }} onClick={() => { useCases?.campaignUseCase.UpdateCampaign(props.campaign.Id(), props.campaign.Name(), props.campaign.AudienceId(), CampaignStatus.Canceled, props.campaign.AudioId(), props.campaign.Speed(), props.campaign.Type()) }} data-title="Стоп"><Square size={25} /></span> : <></>}
            {props.campaign.Status() !== "archived" ? <span style={{ margin: 0, padding: 0, cursor: "pointer" }} onClick={() => { useCases?.campaignUseCase.UpdateCampaign(props.campaign.Id(), props.campaign.Name(), props.campaign.AudienceId(), CampaignStatus.Archived, props.campaign.AudioId(), props.campaign.Speed(), props.campaign.Type()) }} data-title="В архив"><Archive size={25} /></span> : <></>}
            <Link to={"/campaign/statistic/"+props.campaign.Id()} ><span style={{ margin: 0, padding: 0, cursor: "pointer", }}><PieChart size={25} /></span></Link>
            {props.campaign.Status() == "finished" ? <span style={{ margin: 0, padding: 0, cursor: "pointer" }} onClick={() => { useCases?.campaignUseCase.DeleteCampaign(props.campaign.Id()) }} data-title="В архив"><Trash color="red" size={25} /></span> : <></>}
        </td>
    </tr>)
}
