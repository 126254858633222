import { Dispatch } from "react"
import { CampaignActionTypes } from "../action-types";
import { CampaignActions } from "../actions"
import { Campaign } from "../../domain/campaign/campaign";
import { CampaignPriceStruct } from "../../repository/api/generated";

export const SendCampaignListRequest = () => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_REQUEST_SEND,
          });
    }
}

export const CampaignListSuccess = (res: {list: Campaign[], count: number, progress: {[key: string]: number}, prices: {[key: string]: CampaignPriceStruct}}) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_SUCCESS,
            payload: res,
        });
    }
}

export const CampaignListError = (error:string) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_ERROR,
            payload: error,
        });
    }
}

export const CampaignCreateRequest = () => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_CREATE_REQUEST,
          });
    }
}

export const CampaignCreateSuccess = (campaign: Campaign) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_CREATE_SUCCESS,
            payload: campaign,
          });
    }
}

export const CampaignCreateError = (message: string) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_CREATE_ERROR,
            payload: message,
          });
    }
}


export const CampaignUpdateRequest = () => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_UPDATE_REQUEST,
          });
    }
}

export const CampaignUpdateSuccess = (category: Campaign) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const CampaignUpdateError = (message: string) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const CampaignDeleteRequest = () => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_DELETE_REQUEST,
          });
    }
}

export const CampaignDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const CampaignDeleteError = (message: string) => {
    return async (dispatch: Dispatch<CampaignActions>) => {
        dispatch({
            type: CampaignActionTypes.CAMPAIGN_DELETE_ERROR,
            payload: message,
          });
    }
}