import { BlockedsRepository } from "../../repository/api/blocked/blocked";
import { actionCreators } from "../../state";

export class BlockedUseCases {
    private api: BlockedsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: BlockedsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadBlocked = async (offset: number, limit: number) => {
        this.stateActions.SendBlockedListRequest()
        let response = await this.api.ReadBlockedList(offset, limit)
        if (response instanceof Error) {
            this.stateActions.BlockedListError(response.message)
            return
        } else {
            this.stateActions.BlockedListSuccess(response)
        }
    }

    public CreateBlocked = async (phone: File, callback?: ()=>void) => {
        this.stateActions.BlockedCreateRequest()
        let response = await this.api.CreateBlocked(phone)
        if (response instanceof Error) {
            this.stateActions.BlockedCreateError(response.message)
            return
        } else {
            //this.stateActions.BlockedCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateBlocked = async (id: number, phone:string, callback?: ()=>void) => {
        this.stateActions.BlockedUpdateRequest()
        let response = await this.api.UpdateBlocked(id, phone)
        if (response instanceof Error) {
            this.stateActions.BlockedUpdateError(response.message)
            return
        } else {
            this.stateActions.BlockedUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteBlocked = async (id: number) => {
        this.stateActions.BlockedDeleteRequest()
        let response = await this.api.DeleteBlocked(id)
        if (response instanceof Error) {
            this.stateActions.BlockedDeleteError(response.message)
            return
        } else {
            this.stateActions.BlockedDeleteSuccess(id)
        }
    }

}