/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AudienceAudienceItem
 */
export interface AudienceAudienceItem {
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceItem
     */
    'audience_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudienceItem
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface AudienceAudienceItemListResponse
 */
export interface AudienceAudienceItemListResponse {
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceItemListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AudienceAudienceItem>}
     * @memberof AudienceAudienceItemListResponse
     */
    'list'?: Array<AudienceAudienceItem>;
}
/**
 * 
 * @export
 * @interface AudienceAudienceListResponse
 */
export interface AudienceAudienceListResponse {
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AudienceAudienceResponse>}
     * @memberof AudienceAudienceListResponse
     */
    'list'?: Array<AudienceAudienceResponse>;
}
/**
 * 
 * @export
 * @interface AudienceAudienceResponse
 */
export interface AudienceAudienceResponse {
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceResponse
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudienceResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof AudienceAudienceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudienceResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudienceResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudienceResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AudienceAudioListResponse
 */
export interface AudienceAudioListResponse {
    /**
     * 
     * @type {number}
     * @memberof AudienceAudioListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AudienceAudioResponse>}
     * @memberof AudienceAudioListResponse
     */
    'list'?: Array<AudienceAudioResponse>;
}
/**
 * 
 * @export
 * @interface AudienceAudioResponse
 */
export interface AudienceAudioResponse {
    /**
     * 
     * @type {string}
     * @memberof AudienceAudioResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {TimeDuration}
     * @memberof AudienceAudioResponse
     */
    'duration'?: TimeDuration;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudioResponse
     */
    'file'?: string;
    /**
     * 
     * @type {number}
     * @memberof AudienceAudioResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudioResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudioResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceAudioResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AudienceUpdateAudienceRequest
 */
export interface AudienceUpdateAudienceRequest {
    /**
     * 
     * @type {number}
     * @memberof AudienceUpdateAudienceRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceUpdateAudienceRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceUpdateAudienceRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AudienceUpdateAudioRequest
 */
export interface AudienceUpdateAudioRequest {
    /**
     * 
     * @type {TimeDuration}
     * @memberof AudienceUpdateAudioRequest
     */
    'duration'?: TimeDuration;
    /**
     * 
     * @type {string}
     * @memberof AudienceUpdateAudioRequest
     */
    'file'?: string;
    /**
     * 
     * @type {number}
     * @memberof AudienceUpdateAudioRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudienceUpdateAudioRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceUpdateAudioRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface BlockedBlockedListResponse
 */
export interface BlockedBlockedListResponse {
    /**
     * 
     * @type {number}
     * @memberof BlockedBlockedListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<BlockedBlockedResponse>}
     * @memberof BlockedBlockedListResponse
     */
    'list'?: Array<BlockedBlockedResponse>;
}
/**
 * 
 * @export
 * @interface BlockedBlockedResponse
 */
export interface BlockedBlockedResponse {
    /**
     * 
     * @type {string}
     * @memberof BlockedBlockedResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlockedBlockedResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockedBlockedResponse
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface BlockedUpdateBlockedRequest
 */
export interface BlockedUpdateBlockedRequest {
    /**
     * 
     * @type {number}
     * @memberof BlockedUpdateBlockedRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockedUpdateBlockedRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CampaignCampaignListResponse
 */
export interface CampaignCampaignListResponse {
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CampaignCampaignResponse>}
     * @memberof CampaignCampaignListResponse
     */
    'list'?: Array<CampaignCampaignResponse>;
    /**
     * 
     * @type {{ [key: string]: CampaignPriceStruct; }}
     * @memberof CampaignCampaignListResponse
     */
    'prices'?: { [key: string]: CampaignPriceStruct; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CampaignCampaignListResponse
     */
    'progress'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface CampaignCampaignResponse
 */
export interface CampaignCampaignResponse {
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignResponse
     */
    'audience_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignResponse
     */
    'audio_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignResponse
     */
    'speed'?: number;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignCampaignResponse
     */
    'status'?: CampaignStatus;
    /**
     * 
     * @type {CampaignCtype}
     * @memberof CampaignCampaignResponse
     */
    'type'?: CampaignCtype;
}
/**
 * 
 * @export
 * @interface CampaignCampaignSummaryListResponse
 */
export interface CampaignCampaignSummaryListResponse {
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CampaignCampaignSummaryResponse>}
     * @memberof CampaignCampaignSummaryListResponse
     */
    'list'?: Array<CampaignCampaignSummaryResponse>;
}
/**
 * 
 * @export
 * @interface CampaignCampaignSummaryResponse
 */
export interface CampaignCampaignSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'calls_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignSummaryResponse
     */
    'campaign_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignSummaryResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'leads_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'leads_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignSummaryResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCampaignSummaryResponse
     */
    'money'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCampaignSummaryResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CampaignCreateCampaignRequest
 */
export interface CampaignCreateCampaignRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateCampaignRequest
     */
    'audience_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateCampaignRequest
     */
    'audio_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreateCampaignRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateCampaignRequest
     */
    'speed'?: number;
    /**
     * 
     * @type {CampaignCtype}
     * @memberof CampaignCreateCampaignRequest
     */
    'type'?: CampaignCtype;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CampaignCtype = {
    Audio: 'audio',
    Hangup: 'hangup'
} as const;

export type CampaignCtype = typeof CampaignCtype[keyof typeof CampaignCtype];


/**
 * 
 * @export
 * @interface CampaignPriceStruct
 */
export interface CampaignPriceStruct {
    /**
     * 
     * @type {number}
     * @memberof CampaignPriceStruct
     */
    'one_lead_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignPriceStruct
     */
    'success_leads_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignPriceStruct
     */
    'total_price'?: number;
}
/**
 * 
 * @export
 * @interface CampaignStatisticsOfCampaign
 */
export interface CampaignStatisticsOfCampaign {
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'answered_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'answering_machine_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'leads_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'leads_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'not_answered_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'phone_numbers_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'recall_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'spent_money'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatisticsOfCampaign
     */
    'tasks_count'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CampaignStatus = {
    EmptyStatus: '',
    Processing: 'processing',
    Paused: 'paused',
    Canceled: 'canceled',
    Finished: 'finished',
    Archived: 'archived'
} as const;

export type CampaignStatus = typeof CampaignStatus[keyof typeof CampaignStatus];


/**
 * 
 * @export
 * @interface CampaignUpdateCampaignRequest
 */
export interface CampaignUpdateCampaignRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignUpdateCampaignRequest
     */
    'audience_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignUpdateCampaignRequest
     */
    'audio_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignUpdateCampaignRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdateCampaignRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignUpdateCampaignRequest
     */
    'speed'?: number;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignUpdateCampaignRequest
     */
    'status'?: CampaignStatus;
    /**
     * 
     * @type {CampaignCtype}
     * @memberof CampaignUpdateCampaignRequest
     */
    'type'?: CampaignCtype;
}
/**
 * 
 * @export
 * @interface DeliveryErrorResponse
 */
export interface DeliveryErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DidCreateDidRequest
 */
export interface DidCreateDidRequest {
    /**
     * 
     * @type {string}
     * @memberof DidCreateDidRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface DidDidResponse
 */
export interface DidDidResponse {
    /**
     * 
     * @type {string}
     * @memberof DidDidResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof DidDidResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DidDidResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DidDidResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DidDidResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface DidUpdateDidRequest
 */
export interface DidUpdateDidRequest {
    /**
     * 
     * @type {number}
     * @memberof DidUpdateDidRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DidUpdateDidRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DidUpdateDidRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimeDuration = {
    minDuration: -9223372036854775808,
    maxDuration: 9223372036854775807,
    Nanosecond: 1,
    Microsecond: 1000,
    Millisecond: 1000000,
    Second: 1000000000,
    Minute: 60000000000,
    Hour: 3600000000000
} as const;

export type TimeDuration = typeof TimeDuration[keyof typeof TimeDuration];


/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateUserRequest
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateUserRequest
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUserResponse
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface VoipbotInternalDeliveryAuthSignInResponse
 */
export interface VoipbotInternalDeliveryAuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof VoipbotInternalDeliveryAuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoipbotInternalDeliveryAuthSignInResponse
     */
    'token'?: string;
}

/**
 * AudienceApi - axios parameter creator
 * @export
 */
export const AudienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех аудиторий.
         * @summary получить список всех аудиторий.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceGet: async (offset?: number, limit?: number, status?: 'active' | 'archived', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audience/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceIdDelete', 'id', id)
            const localVarPath = `/api/audience/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить аудиторию по id.
         * @summary получить аудиторию по id.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceIdGet', 'id', id)
            const localVarPath = `/api/audience/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * добавление контактов в аудиторию.
         * @summary добавление контактов в аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdItemPut: async (id: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceIdItemPut', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAudienceIdItemPut', 'file', file)
            const localVarPath = `/api/audience/{id}/item`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление аудитории.
         * @summary обновление аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {AudienceUpdateAudienceRequest} auditory Данные для обновления аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdPut: async (id: string, auditory: AudienceUpdateAudienceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceIdPut', 'id', id)
            // verify required parameter 'auditory' is not null or undefined
            assertParamExists('apiAudienceIdPut', 'auditory', auditory)
            const localVarPath = `/api/audience/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceItemIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceItemIdDelete', 'id', id)
            const localVarPath = `/api/audience/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список номеров аудитории.
         * @summary получить список номеров аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceItemIdGet: async (id: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudienceItemIdGet', 'id', id)
            const localVarPath = `/api/audience/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание аудитории.
         * @summary создание аудитории.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudiencePost: async (file: File, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAudiencePost', 'file', file)
            const localVarPath = `/api/audience/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudienceApi - functional programming interface
 * @export
 */
export const AudienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudienceApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех аудиторий.
         * @summary получить список всех аудиторий.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceGet(offset, limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить аудиторию по id.
         * @summary получить аудиторию по id.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * добавление контактов в аудиторию.
         * @summary добавление контактов в аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceIdItemPut(id: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceIdItemPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление аудитории.
         * @summary обновление аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {AudienceUpdateAudienceRequest} auditory Данные для обновления аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceIdPut(id: string, auditory: AudienceUpdateAudienceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceIdPut(id, auditory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceItemIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список номеров аудитории.
         * @summary получить список номеров аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudienceItemIdGet(id: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudienceItemIdGet(id, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание аудитории.
         * @summary создание аудитории.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudiencePost(file: File, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudiencePost(file, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudienceApi - factory interface
 * @export
 */
export const AudienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudienceApiFp(configuration)
    return {
        /**
         * получить список всех аудиторий.
         * @summary получить список всех аудиторий.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: any): AxiosPromise<AudienceAudienceListResponse> {
            return localVarFp.apiAudienceGet(offset, limit, status, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdDelete(id: string, options?: any): AxiosPromise<AudienceAudienceResponse> {
            return localVarFp.apiAudienceIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить аудиторию по id.
         * @summary получить аудиторию по id.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdGet(id: string, options?: any): AxiosPromise<AudienceAudienceResponse> {
            return localVarFp.apiAudienceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * добавление контактов в аудиторию.
         * @summary добавление контактов в аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdItemPut(id: string, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiAudienceIdItemPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление аудитории.
         * @summary обновление аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {AudienceUpdateAudienceRequest} auditory Данные для обновления аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceIdPut(id: string, auditory: AudienceUpdateAudienceRequest, options?: any): AxiosPromise<AudienceAudienceResponse> {
            return localVarFp.apiAudienceIdPut(id, auditory, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить аудиторию.
         * @summary удалить аудиторию.
         * @param {string} id Идентификатор аудитории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceItemIdDelete(id: string, options?: any): AxiosPromise<AudienceAudienceResponse> {
            return localVarFp.apiAudienceItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список номеров аудитории.
         * @summary получить список номеров аудитории.
         * @param {string} id Идентификатор аудитории
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudienceItemIdGet(id: string, offset?: number, limit?: number, options?: any): AxiosPromise<AudienceAudienceItemListResponse> {
            return localVarFp.apiAudienceItemIdGet(id, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * создание аудитории.
         * @summary создание аудитории.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudiencePost(file: File, name?: string, options?: any): AxiosPromise<AudienceAudienceResponse> {
            return localVarFp.apiAudiencePost(file, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudienceApi - object-oriented interface
 * @export
 * @class AudienceApi
 * @extends {BaseAPI}
 */
export class AudienceApi extends BaseAPI {
    /**
     * получить список всех аудиторий.
     * @summary получить список всех аудиторий.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {'active' | 'archived'} [status] Статус: active; archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceGet(offset, limit, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить аудиторию.
     * @summary удалить аудиторию.
     * @param {string} id Идентификатор аудитории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceIdDelete(id: string, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить аудиторию по id.
     * @summary получить аудиторию по id.
     * @param {string} id Идентификатор аудитории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceIdGet(id: string, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * добавление контактов в аудиторию.
     * @summary добавление контактов в аудиторию.
     * @param {string} id Идентификатор аудитории
     * @param {File} file Audio file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceIdItemPut(id: string, file: File, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceIdItemPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление аудитории.
     * @summary обновление аудитории.
     * @param {string} id Идентификатор аудитории
     * @param {AudienceUpdateAudienceRequest} auditory Данные для обновления аудитории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceIdPut(id: string, auditory: AudienceUpdateAudienceRequest, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceIdPut(id, auditory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить аудиторию.
     * @summary удалить аудиторию.
     * @param {string} id Идентификатор аудитории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceItemIdDelete(id: string, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список номеров аудитории.
     * @summary получить список номеров аудитории.
     * @param {string} id Идентификатор аудитории
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudienceItemIdGet(id: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudienceItemIdGet(id, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание аудитории.
     * @summary создание аудитории.
     * @param {File} file Audio file
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public apiAudiencePost(file: File, name?: string, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).apiAudiencePost(file, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AudioApi - axios parameter creator
 * @export
 */
export const AudioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * обновление аудио файла.
         * @summary обновление аудио файла.
         * @param {string} id Идентификатор аудио
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPut: async (id: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdPut', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAudioFileIdPut', 'file', file)
            const localVarPath = `/api/audio/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioGet: async (offset?: number, limit?: number, status?: 'active' | 'archived', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audio/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioIdDelete', 'id', id)
            const localVarPath = `/api/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить аудио по id.
         * @summary получить аудио по id.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioIdGet', 'id', id)
            const localVarPath = `/api/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление аудио.
         * @summary обновление аудио.
         * @param {string} id Идентификатор аудио
         * @param {AudienceUpdateAudioRequest} data Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdPut: async (id: string, data: AudienceUpdateAudioRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiAudioIdPut', 'data', data)
            const localVarPath = `/api/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание аудио.
         * @summary создание аудио.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioPost: async (file: File, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAudioPost', 'file', file)
            const localVarPath = `/api/audio/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudioApi - functional programming interface
 * @export
 */
export const AudioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudioApiAxiosParamCreator(configuration)
    return {
        /**
         * обновление аудио файла.
         * @summary обновление аудио файла.
         * @param {string} id Идентификатор аудио
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdPut(id: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioGet(offset, limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить аудио по id.
         * @summary получить аудио по id.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление аудио.
         * @summary обновление аудио.
         * @param {string} id Идентификатор аудио
         * @param {AudienceUpdateAudioRequest} data Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioIdPut(id: string, data: AudienceUpdateAudioRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание аудио.
         * @summary создание аудио.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioPost(file: File, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudienceAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioPost(file, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudioApi - factory interface
 * @export
 */
export const AudioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudioApiFp(configuration)
    return {
        /**
         * обновление аудио файла.
         * @summary обновление аудио файла.
         * @param {string} id Идентификатор аудио
         * @param {File} file Audio file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPut(id: string, file: File, options?: any): AxiosPromise<AudienceAudioResponse> {
            return localVarFp.apiAudioFileIdPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: any): AxiosPromise<AudienceAudioListResponse> {
            return localVarFp.apiAudioGet(offset, limit, status, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdDelete(id: string, options?: any): AxiosPromise<AudienceAudioResponse> {
            return localVarFp.apiAudioIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить аудио по id.
         * @summary получить аудио по id.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdGet(id: string, options?: any): AxiosPromise<AudienceAudioResponse> {
            return localVarFp.apiAudioIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление аудио.
         * @summary обновление аудио.
         * @param {string} id Идентификатор аудио
         * @param {AudienceUpdateAudioRequest} data Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioIdPut(id: string, data: AudienceUpdateAudioRequest, options?: any): AxiosPromise<AudienceAudioResponse> {
            return localVarFp.apiAudioIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * создание аудио.
         * @summary создание аудио.
         * @param {File} file Audio file
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioPost(file: File, name?: string, options?: any): AxiosPromise<AudienceAudioResponse> {
            return localVarFp.apiAudioPost(file, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudioApi - object-oriented interface
 * @export
 * @class AudioApi
 * @extends {BaseAPI}
 */
export class AudioApi extends BaseAPI {
    /**
     * обновление аудио файла.
     * @summary обновление аудио файла.
     * @param {string} id Идентификатор аудио
     * @param {File} file Audio file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioFileIdPut(id: string, file: File, options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioFileIdPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех аудиозаписей.
     * @summary получить список всех аудиозаписей.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {'active' | 'archived'} [status] Статус: active; archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioGet(offset, limit, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить аудио.
     * @summary удалить аудио.
     * @param {string} id Идентификатор аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioIdDelete(id: string, options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить аудио по id.
     * @summary получить аудио по id.
     * @param {string} id Идентификатор аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioIdGet(id: string, options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление аудио.
     * @summary обновление аудио.
     * @param {string} id Идентификатор аудио
     * @param {AudienceUpdateAudioRequest} data Данные для обновления аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioIdPut(id: string, data: AudienceUpdateAudioRequest, options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание аудио.
     * @summary создание аудио.
     * @param {File} file Audio file
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioApi
     */
    public apiAudioPost(file: File, name?: string, options?: AxiosRequestConfig) {
        return AudioApiFp(this.configuration).apiAudioPost(file, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoipbotInternalDeliveryAuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<VoipbotInternalDeliveryAuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlockedApi - axios parameter creator
 * @export
 */
export const BlockedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedGet: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blocked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlockedIdDelete', 'id', id)
            const localVarPath = `/api/blocked/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление заблокированного номера.
         * @summary обновление заблокированного номера.
         * @param {string} id Идентификатор аудио
         * @param {BlockedUpdateBlockedRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedIdPut: async (id: string, auditory: BlockedUpdateBlockedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlockedIdPut', 'id', id)
            // verify required parameter 'auditory' is not null or undefined
            assertParamExists('apiBlockedIdPut', 'auditory', auditory)
            const localVarPath = `/api/blocked/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * загрузка заблокированных номеров.
         * @summary загрузка заблокированных номеров.
         * @param {File} file Blocked list file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiBlockedPost', 'file', file)
            const localVarPath = `/api/blocked/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockedApi - functional programming interface
 * @export
 */
export const BlockedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlockedApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockedGet(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedBlockedListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockedGet(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockedIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedBlockedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockedIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление заблокированного номера.
         * @summary обновление заблокированного номера.
         * @param {string} id Идентификатор аудио
         * @param {BlockedUpdateBlockedRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockedIdPut(id: string, auditory: BlockedUpdateBlockedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedBlockedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockedIdPut(id, auditory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * загрузка заблокированных номеров.
         * @summary загрузка заблокированных номеров.
         * @param {File} file Blocked list file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockedPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockedPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlockedApi - factory interface
 * @export
 */
export const BlockedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlockedApiFp(configuration)
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedGet(offset?: number, limit?: number, options?: any): AxiosPromise<BlockedBlockedListResponse> {
            return localVarFp.apiBlockedGet(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedIdDelete(id: string, options?: any): AxiosPromise<BlockedBlockedResponse> {
            return localVarFp.apiBlockedIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление заблокированного номера.
         * @summary обновление заблокированного номера.
         * @param {string} id Идентификатор аудио
         * @param {BlockedUpdateBlockedRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedIdPut(id: string, auditory: BlockedUpdateBlockedRequest, options?: any): AxiosPromise<BlockedBlockedResponse> {
            return localVarFp.apiBlockedIdPut(id, auditory, options).then((request) => request(axios, basePath));
        },
        /**
         * загрузка заблокированных номеров.
         * @summary загрузка заблокированных номеров.
         * @param {File} file Blocked list file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockedPost(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiBlockedPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockedApi - object-oriented interface
 * @export
 * @class BlockedApi
 * @extends {BaseAPI}
 */
export class BlockedApi extends BaseAPI {
    /**
     * получить список всех аудиозаписей.
     * @summary получить список всех аудиозаписей.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedApi
     */
    public apiBlockedGet(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return BlockedApiFp(this.configuration).apiBlockedGet(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить аудио.
     * @summary удалить аудио.
     * @param {string} id Идентификатор аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedApi
     */
    public apiBlockedIdDelete(id: string, options?: AxiosRequestConfig) {
        return BlockedApiFp(this.configuration).apiBlockedIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление заблокированного номера.
     * @summary обновление заблокированного номера.
     * @param {string} id Идентификатор аудио
     * @param {BlockedUpdateBlockedRequest} auditory Данные для обновления аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedApi
     */
    public apiBlockedIdPut(id: string, auditory: BlockedUpdateBlockedRequest, options?: AxiosRequestConfig) {
        return BlockedApiFp(this.configuration).apiBlockedIdPut(id, auditory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * загрузка заблокированных номеров.
     * @summary загрузка заблокированных номеров.
     * @param {File} file Blocked list file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedApi
     */
    public apiBlockedPost(file: File, options?: AxiosRequestConfig) {
        return BlockedApiFp(this.configuration).apiBlockedPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех компаний.
         * @summary получить список всех компаний.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignGet: async (offset?: number, limit?: number, status?: '' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить компании.
         * @summary удалить компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdDelete', 'id', id)
            const localVarPath = `/api/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить компании по id.
         * @summary получить компании по id.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdGet', 'id', id)
            const localVarPath = `/api/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список лидов.
         * @summary получить список лидов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdLeadsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdLeadsGet', 'id', id)
            const localVarPath = `/api/campaign/{id}/leads`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * пауза компании.
         * @summary пауза компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPausePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdPausePost', 'id', id)
            const localVarPath = `/api/campaign/{id}/pause`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * плей компании.
         * @summary плей компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPlayPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdPlayPost', 'id', id)
            const localVarPath = `/api/campaign/{id}/play`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление компании.
         * @summary обновление компании.
         * @param {string} id Идентификатор компании
         * @param {CampaignUpdateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPut: async (id: string, data: CampaignUpdateCampaignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiCampaignIdPut', 'data', data)
            const localVarPath = `/api/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить статистику по компании.
         * @summary получить статистику по компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdStatGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdStatGet', 'id', id)
            const localVarPath = `/api/campaign/{id}/stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список ответивших контактов.
         * @summary получить список ответивших контактов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdSuccessGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdSuccessGet', 'id', id)
            const localVarPath = `/api/campaign/{id}/success`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список не ответивших контактов (автоответчик + не ответившие).
         * @summary получить список не ответивших контактов (автоответчик + не ответившие).
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdUnsuccessGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCampaignIdUnsuccessGet', 'id', id)
            const localVarPath = `/api/campaign/{id}/unsuccess`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание компании.
         * @summary создание компании.
         * @param {CampaignCreateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignPost: async (data: CampaignCreateCampaignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiCampaignPost', 'data', data)
            const localVarPath = `/api/campaign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список cтатистики компании.
         * @summary получить список cтатистики компании.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignSummaryGet: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех компаний.
         * @summary получить список всех компаний.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignGet(offset?: number, limit?: number, status?: '' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignGet(offset, limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить компании.
         * @summary удалить компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить компании по id.
         * @summary получить компании по id.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список лидов.
         * @summary получить список лидов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdLeadsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdLeadsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * пауза компании.
         * @summary пауза компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdPausePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdPausePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * плей компании.
         * @summary плей компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdPlayPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdPlayPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление компании.
         * @summary обновление компании.
         * @param {string} id Идентификатор компании
         * @param {CampaignUpdateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdPut(id: string, data: CampaignUpdateCampaignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить статистику по компании.
         * @summary получить статистику по компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdStatGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignStatisticsOfCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdStatGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список ответивших контактов.
         * @summary получить список ответивших контактов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdSuccessGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdSuccessGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список не ответивших контактов (автоответчик + не ответившие).
         * @summary получить список не ответивших контактов (автоответчик + не ответившие).
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignIdUnsuccessGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignIdUnsuccessGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание компании.
         * @summary создание компании.
         * @param {CampaignCreateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignPost(data: CampaignCreateCampaignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список cтатистики компании.
         * @summary получить список cтатистики компании.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignSummaryGet(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCampaignSummaryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignSummaryGet(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * получить список всех компаний.
         * @summary получить список всех компаний.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignGet(offset?: number, limit?: number, status?: '' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived', options?: any): AxiosPromise<CampaignCampaignListResponse> {
            return localVarFp.apiCampaignGet(offset, limit, status, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить компании.
         * @summary удалить компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdDelete(id: string, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить компании по id.
         * @summary получить компании по id.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdGet(id: string, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список лидов.
         * @summary получить список лидов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdLeadsGet(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiCampaignIdLeadsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * пауза компании.
         * @summary пауза компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPausePost(id: string, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignIdPausePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * плей компании.
         * @summary плей компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPlayPost(id: string, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignIdPlayPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление компании.
         * @summary обновление компании.
         * @param {string} id Идентификатор компании
         * @param {CampaignUpdateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdPut(id: string, data: CampaignUpdateCampaignRequest, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить статистику по компании.
         * @summary получить статистику по компании.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdStatGet(id: string, options?: any): AxiosPromise<CampaignStatisticsOfCampaign> {
            return localVarFp.apiCampaignIdStatGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список ответивших контактов.
         * @summary получить список ответивших контактов.
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdSuccessGet(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiCampaignIdSuccessGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список не ответивших контактов (автоответчик + не ответившие).
         * @summary получить список не ответивших контактов (автоответчик + не ответившие).
         * @param {string} id Идентификатор компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignIdUnsuccessGet(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiCampaignIdUnsuccessGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * создание компании.
         * @summary создание компании.
         * @param {CampaignCreateCampaignRequest} data Данные компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignPost(data: CampaignCreateCampaignRequest, options?: any): AxiosPromise<CampaignCampaignResponse> {
            return localVarFp.apiCampaignPost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список cтатистики компании.
         * @summary получить список cтатистики компании.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignSummaryGet(offset?: number, limit?: number, options?: any): AxiosPromise<CampaignCampaignSummaryListResponse> {
            return localVarFp.apiCampaignSummaryGet(offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * получить список всех компаний.
     * @summary получить список всех компаний.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {'' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived'} [status] Статус: active; archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignGet(offset?: number, limit?: number, status?: '' | 'processing' | 'paused' | 'canceled' | 'finished' | 'archived', options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignGet(offset, limit, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить компании.
     * @summary удалить компании.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdDelete(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить компании по id.
     * @summary получить компании по id.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdGet(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список лидов.
     * @summary получить список лидов.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdLeadsGet(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdLeadsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * пауза компании.
     * @summary пауза компании.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdPausePost(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdPausePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * плей компании.
     * @summary плей компании.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdPlayPost(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdPlayPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление компании.
     * @summary обновление компании.
     * @param {string} id Идентификатор компании
     * @param {CampaignUpdateCampaignRequest} data Данные компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdPut(id: string, data: CampaignUpdateCampaignRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить статистику по компании.
     * @summary получить статистику по компании.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdStatGet(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdStatGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список ответивших контактов.
     * @summary получить список ответивших контактов.
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdSuccessGet(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdSuccessGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список не ответивших контактов (автоответчик + не ответившие).
     * @summary получить список не ответивших контактов (автоответчик + не ответившие).
     * @param {string} id Идентификатор компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignIdUnsuccessGet(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignIdUnsuccessGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание компании.
     * @summary создание компании.
     * @param {CampaignCreateCampaignRequest} data Данные компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignPost(data: CampaignCreateCampaignRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignPost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список cтатистики компании.
     * @summary получить список cтатистики компании.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public apiCampaignSummaryGet(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).apiCampaignSummaryGet(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DidApi - axios parameter creator
 * @export
 */
export const DidApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidGet: async (offset?: number, limit?: number, status?: 'active' | 'archived', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/did`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDidIdDelete', 'id', id)
            const localVarPath = `/api/did/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление подменного номера.
         * @summary обновление подменного номера.
         * @param {string} id Идентификатор аудио
         * @param {DidUpdateDidRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidIdPut: async (id: string, auditory: DidUpdateDidRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDidIdPut', 'id', id)
            // verify required parameter 'auditory' is not null or undefined
            assertParamExists('apiDidIdPut', 'auditory', auditory)
            const localVarPath = `/api/did/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание подменного номера.
         * @summary создание подменного номера.
         * @param {DidCreateDidRequest} data Данные аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidPost: async (data: DidCreateDidRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiDidPost', 'data', data)
            const localVarPath = `/api/did/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DidApi - functional programming interface
 * @export
 */
export const DidApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DidApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDidGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DidDidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDidGet(offset, limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDidIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DidDidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDidIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление подменного номера.
         * @summary обновление подменного номера.
         * @param {string} id Идентификатор аудио
         * @param {DidUpdateDidRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDidIdPut(id: string, auditory: DidUpdateDidRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DidDidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDidIdPut(id, auditory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание подменного номера.
         * @summary создание подменного номера.
         * @param {DidCreateDidRequest} data Данные аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDidPost(data: DidCreateDidRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DidDidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDidPost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DidApi - factory interface
 * @export
 */
export const DidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DidApiFp(configuration)
    return {
        /**
         * получить список всех аудиозаписей.
         * @summary получить список всех аудиозаписей.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {'active' | 'archived'} [status] Статус: active; archived
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: any): AxiosPromise<Array<DidDidResponse>> {
            return localVarFp.apiDidGet(offset, limit, status, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить аудио.
         * @summary удалить аудио.
         * @param {string} id Идентификатор аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidIdDelete(id: string, options?: any): AxiosPromise<DidDidResponse> {
            return localVarFp.apiDidIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление подменного номера.
         * @summary обновление подменного номера.
         * @param {string} id Идентификатор аудио
         * @param {DidUpdateDidRequest} auditory Данные для обновления аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidIdPut(id: string, auditory: DidUpdateDidRequest, options?: any): AxiosPromise<DidDidResponse> {
            return localVarFp.apiDidIdPut(id, auditory, options).then((request) => request(axios, basePath));
        },
        /**
         * создание подменного номера.
         * @summary создание подменного номера.
         * @param {DidCreateDidRequest} data Данные аудио
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDidPost(data: DidCreateDidRequest, options?: any): AxiosPromise<DidDidResponse> {
            return localVarFp.apiDidPost(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DidApi - object-oriented interface
 * @export
 * @class DidApi
 * @extends {BaseAPI}
 */
export class DidApi extends BaseAPI {
    /**
     * получить список всех аудиозаписей.
     * @summary получить список всех аудиозаписей.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {'active' | 'archived'} [status] Статус: active; archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DidApi
     */
    public apiDidGet(offset?: number, limit?: number, status?: 'active' | 'archived', options?: AxiosRequestConfig) {
        return DidApiFp(this.configuration).apiDidGet(offset, limit, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить аудио.
     * @summary удалить аудио.
     * @param {string} id Идентификатор аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DidApi
     */
    public apiDidIdDelete(id: string, options?: AxiosRequestConfig) {
        return DidApiFp(this.configuration).apiDidIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление подменного номера.
     * @summary обновление подменного номера.
     * @param {string} id Идентификатор аудио
     * @param {DidUpdateDidRequest} auditory Данные для обновления аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DidApi
     */
    public apiDidIdPut(id: string, auditory: DidUpdateDidRequest, options?: AxiosRequestConfig) {
        return DidApiFp(this.configuration).apiDidIdPut(id, auditory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание подменного номера.
     * @summary создание подменного номера.
     * @param {DidCreateDidRequest} data Данные аудио
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DidApi
     */
    public apiDidPost(data: DidCreateDidRequest, options?: AxiosRequestConfig) {
        return DidApiFp(this.configuration).apiDidPost(data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * получить список всех источников (не админов).
     * @summary получить список всех пользователей (не админов).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


