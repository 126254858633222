import { Dispatch } from "react"
import { DidActionTypes } from "../action-types";
import { DidActions } from "../actions"
import { Did } from "../../domain/did/did";

export const SendDidListRequest = () => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_REQUEST_SEND,
          });
    }
}

export const DidListSuccess = (list: Did[]) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_SUCCESS,
            payload: list,
        });
    }
}

export const DidListError = (error:string) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_ERROR,
            payload: error,
        });
    }
}

export const DidCreateRequest = () => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_CREATE_REQUEST,
          });
    }
}

export const DidCreateSuccess = (did: Did) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_CREATE_SUCCESS,
            payload: did,
          });
    }
}

export const DidCreateError = (message: string) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_CREATE_ERROR,
            payload: message,
          });
    }
}


export const DidUpdateRequest = () => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_UPDATE_REQUEST,
          });
    }
}

export const DidUpdateSuccess = (did: Did) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_UPDATE_SUCCESS,
            payload: did,
          });
    }
}

export const DidUpdateError = (message: string) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const DidDeleteRequest = () => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_DELETE_REQUEST,
          });
    }
}

export const DidDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const DidDeleteError = (message: string) => {
    return async (dispatch: Dispatch<DidActions>) => {
        dispatch({
            type: DidActionTypes.DID_DELETE_ERROR,
            payload: message,
          });
    }
}