export enum AudienceActionTypes {
    AUDIENCE_REQUEST_SEND = 'audience_request_send',
    AUDIENCE_ERROR = 'audience_error',
    AUDIENCE_SUCCESS = 'audience_success',
    
    AUDIENCE_CREATE_REQUEST = 'audience_create_request',
    AUDIENCE_CREATE_SUCCESS = 'audience_create_success',
    AUDIENCE_CREATE_ERROR = 'audience_create_error',

    AUDIENCE_DELETE_REQUEST = 'audience_delete_request',
    AUDIENCE_DELETE_SUCCESS = 'audience_delete_success',
    AUDIENCE_DELETE_ERROR = 'audience_delete_error',

    AUDIENCE_UPDATE_REQUEST = 'audience_update_request',
    AUDIENCE_UPDATE_SUCCESS = 'audience_update_success',
    AUDIENCE_UPDATE_ERROR = 'audience_update_error',


    AUDIENCE_ITEM_REQUEST_SEND = 'audience_item_request_send',
    AUDIENCE_ITEM_ERROR = 'audience_item_error',
    AUDIENCE_ITEM_SUCCESS = 'audience_item_success',

    AUDIENCE_ITEM_DELETE_REQUEST = 'audience_item_delete_request',
    AUDIENCE_ITEM_DELETE_SUCCESS = 'audience_item_delete_success',
    AUDIENCE_ITEM_DELETE_ERROR = 'audience_item_delete_error',
}