export class Campaign {
     
    private id: number; 
    private name: string; 
    private audienceId: number; 
    private audioId: number;
    private speed: number; 
    private status: string; 
    private type: "audio" | "hangup"; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: number, name: string, audienceId: number, audioId: number, speed: number, status: string, type: "audio" | "hangup", createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.audienceId = audienceId;
        this.audioId = audioId;
        this.speed = speed;
        this.status = status;
        this.type = type;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public AudienceId = (): number => {
        return this.audienceId
    }

    public AudioId = (): number => {
        return this.audioId
    }
    
    public Speed = (): number => {
        return this.speed
    }
    public Status = (): string => {
        return this.status
    }

    public Type = (): "audio" | "hangup" => {
        return this.type
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}