import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { Link, } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import Paginator from "../../../components/paginator/paginator"
import { Filter } from "tabler-icons-react"
import "./style.css"
import { CampaignCampaignSummaryResponse } from "../../../../repository/api/generated"

export const SummaryList = () => {
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(25)

    const [count, setCount] = useState(0)
    const [summaryList, setSummaryList] = useState<CampaignCampaignSummaryResponse[]>([])

    let useCases = useContext(UseCasesContext)

    const readSummaryList = async () => {
        let response = await useCases!.campaignUseCase.ReadCampaignSummaryList(page * countOnPage, countOnPage)
        if (response instanceof Error) {
            
        } else {
            setSummaryList(response.list)
            setCount(response.count)
        }
    }

    useEffect(() => {
        readSummaryList()
    }, [count, page])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Статистика рассылок /</span> Статистика</h4>
                        </div>
                        <div className="card">
                            <div className="table-responsive text-nowrap_">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th style={{width: "15%"}}><strong>Дата</strong></th>
                                            <th style={{width: "20%"}}><strong>Название</strong></th>
                                            <th><strong>Кол-во<br />лидов</strong></th>
                                            <th><strong>Всего</strong></th>
                                            <th><strong>Результаты</strong><br /><small>потрачено / цена лида</small> </th>
                                            <th><strong>Тип</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            summaryList?.map((item, index) => {
                                                return <TableRow summary={item}  last={summaryList.length - 1 === index ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "10px", justifyContent: "end" }}>
                            <Paginator allCount={count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}