import axios from "axios";
import { Did, } from "../../../domain/did/did";
import { Configuration, DidApi } from "../generated";
import { DidInterface } from "../../../useCase/did/adapters/api/interface";

export class DidsRepository implements DidInterface {
    private service: DidApi;

    constructor() {
        this.service = new DidApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadDids(offset: number, limit: number, status: 'active' | 'archived'): Promise<Did[] | Error> {
        try {
            let response = await this.service.apiDidGet(offset, limit, status, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let dids: Did[] = []
                response.data.forEach(didData => {
                    let createdAt = new Date(didData.created_at!)
                    let modifiedAt = new Date(didData.modified_at!)
                    let did = new Did(didData.id!, didData.phone!, didData.status!, createdAt, modifiedAt)
                    dids.push(did)
                });
                return dids
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateDid(phone: string): Promise<Did | Error> {
        try {
            let response = await this.service.apiDidPost({ phone: phone }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let did = new Did(response.data.id!, response.data.phone!, response.data.status!, createdAt, modifiedAt)
                return did
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateDid(id: number, phone: string, status: 'active' | 'archived'): Promise<Did | Error> {
        try {
            let response = await this.service.apiDidIdPut(id.toString(), { phone: phone, status: status }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let did = new Did(response.data.id!, response.data.phone!, response.data.status!, createdAt, modifiedAt)
                return did
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteDid(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiDidIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

}