import { combineReducers } from "redux";
import loginReducer from './loginReducer';
 
import audienceReducer from './audience/audience'; 
import audioReducer from './audio/audio'; 
import campaignReducer from './campaign/campaign'; 
import didReducer from './did/did';
import blockedReducer from './blocked/blocked'; 

const reducers = combineReducers({
    login: loginReducer,
    audience : audienceReducer,
    audio : audioReducer,
    campaign : campaignReducer,
    did : didReducer,
    blocked: blockedReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;