import { Audience } from "../../domain/audience/audience";
import { AudiencesRepository } from "../../repository/api/audience/audience";
import { actionCreators } from "../../state";

export class AudienceUseCases {
    private api: AudiencesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: AudiencesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadAudienceList = async (offset: number, limit: number, status: "active"|"archived") => {
        this.stateActions.SendAudienceListRequest()
        let response = await this.api.ReadAudienceList(offset, limit, status)
        if (response instanceof Error) {
            this.stateActions.AudienceListError(response.message)
            return
        } else {
            this.stateActions.AudienceListSuccess(response)
        }
    }

    public ReadAudienceItemList = async (audiencedId: number, offset: number, limit: number) => {
        this.stateActions.SendAudienceItemListRequest()
        let response = await this.api.ReadAudienceListItems(audiencedId, offset, limit)
        if (response instanceof Error) {
            this.stateActions.AudienceListError(response.message)
            return
        } else {
            this.stateActions.AudienceItemListSuccess(response)
        }
    }

    public CreateAudience = async (name: string, file: File, callback?: ()=>void) => {
        this.stateActions.AudienceCreateRequest()
        let response = await this.api.CreateAudience(name, file)
        if (response instanceof Error) {
            this.stateActions.AudienceCreateError(response.message)
            return
        } else {
            this.stateActions.AudienceCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public CreateItemsInAudience = async (audienceId: number, file: File, callback?: ()=>void) => {
        this.stateActions.AudienceCreateRequest()
        let response = await this.api.CreateItemsInAudience(audienceId, file)
        if (response instanceof Error) {
            this.stateActions.AudienceCreateError(response.message)
            return
        } else {
            if (callback) {
                callback()
            }
        }
    }

    public UpdateAudience = async (id: number, name:string, status: "active"|"archived", callback?: ()=>void) => {
        this.stateActions.AudienceUpdateRequest()
        let response = await this.api.UpdateAudience(id, name, status)
        if (response instanceof Error) {
            this.stateActions.AudienceUpdateError(response.message)
            return
        } else {
            this.stateActions.AudienceUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteAudience = async (id: number) => {
        this.stateActions.AudienceDeleteRequest()
        let response = await this.api.DeleteAudience(id)
        if (response instanceof Error) {
            this.stateActions.AudienceDeleteError(response.message)
            return
        } else {
            this.stateActions.AudienceDeleteSuccess(id)
        }
    }

    public DeleteAudienceItem = async (id: number) => {
        this.stateActions.AudienceItemDeleteRequest()
        let response = await this.api.DeleteAudienceItem(id)
        if (response instanceof Error) {
            this.stateActions.AudienceItemDeleteError(response.message)
            return
        } else {
            this.stateActions.AudienceItemDeleteSuccess(id)
        }
    }

}