import { Audio } from "../../domain/audio/audio";
import { AudiosRepository } from "../../repository/api/audio/audio";
import { actionCreators } from "../../state";

export class AudioUseCases {
    private api: AudiosRepository;
    private stateActions: typeof actionCreators;

    constructor(api: AudiosRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadAudio = async (offset: number, limit: number, status: "active"|"archived") => {
        this.stateActions.SendAudioListRequest()
        let response = await this.api.ReadAudios(offset, limit, status)
        if (response instanceof Error) {
            this.stateActions.AudioListError(response.message)
            return
        } else {
            this.stateActions.AudioListSuccess(response)
        }
    }

    public CreateAudio = async (phone: string, file: File, callback?: ()=>void) => {
        this.stateActions.AudioCreateRequest()
        let response = await this.api.CreateAudio(phone, file)
        if (response instanceof Error) {
            this.stateActions.AudioCreateError(response.message)
            return
        } else {
            this.stateActions.AudioCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateAudio = async (id: number, phone:string, status: "active"|"archived", callback?: ()=>void) => {
        this.stateActions.AudioUpdateRequest()
        let response = await this.api.UpdateAudio(id, phone, status)
        if (response instanceof Error) {
            this.stateActions.AudioUpdateError(response.message)
            return
        } else {
            this.stateActions.AudioUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateAudioFile = async (id: number, file: File, callback?: ()=>void) => {
        this.stateActions.AudioUpdateRequest()
        let response = await this.api.UpdateAudioFile(id, file)
        if (response instanceof Error) {
            this.stateActions.AudioUpdateError(response.message)
            return
        } else {
            this.stateActions.AudioUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteAudio = async (id: number) => {
        this.stateActions.AudioDeleteRequest()
        let response = await this.api.DeleteAudio(id)
        if (response instanceof Error) {
            this.stateActions.AudioDeleteError(response.message)
            return
        } else {
            this.stateActions.AudioDeleteSuccess(id)
        }
    }

}